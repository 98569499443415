<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Nível</h1>
        <v-form @submit.prevent="submit()">
          <v-text-field
            v-model="level.name"
            label="Nome"
            name="name"
            type="text"
            required
            :rules="rules"
          />
          <v-text-field
            v-model="level.color"
            label="Cor"
            name="color"
            type="text"
            required
            :rules="rules"
          />
          <v-text-field
            v-model="level.score"
            label="Pontuação"
            name="score"
            type="number"
            required
            :rules="rules"
            :disabled="editing"
          />
          <v-text-field
            v-model="level.cashbackFactor"
            label="Casback X"
            name="score"
            type="number"
            required
            :rules="rules"
            :disabled="editing"
          />
          <v-switch
            v-model="level.disabled"
            label="Desativado?"
          />
          <tiptap-vuetify
            v-model="level.description"
            :extensions="extensions"
          />
          <v-btn
            color="primary"
            type="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    components: {
      TiptapVuetify,
    },
    data: () => ({
      level: {
        disabled: false,
      },
      editing: false,
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.editing = true
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/gamefication/level/${this.$route.params.id}`)
          .then(resp => {
            this.level = resp.data
          })
      },
      submit () {
        this.level.score = parseFloat(this.level.score)
        this.level.cashbackFactor = parseFloat(this.level.cashbackFactor)
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/gamefication/level', this.level)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/gamefication/levels')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/gamefication/level', this.level)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/gamefication/levels')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>
